#map {
  height: 100%;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
}

.marker.team {
  height: 15px;
  border-radius: 50%;
  border: solid black 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-tooltip {
  width: 300px;
  white-space: normal;
}


.marker.team>span {
  position: relative;
  top: -20px;
  left: -50%;
  font-size: 1.1rem;
  font-weight: bolder;
  font: sans-serif;
  white-space: nowrap;
}

.marker.team.bg-1 {
  background-color: red
}

.marker.team.bg-2 {
  background-color: green
}

.marker.team.bg-3 {
  background-color: teal
}

.marker.team.bg-4 {
  background-color: blue
}

.marker.team.bg-5 {
  background-color: violet
}

.marker.team.bg-6 {
  background-color: pink
}

.marker.team.bg-7 {
  background-color: brown
}

.marker.team.bg-8 {
  background-color: purple
}

.marker.team.bg-9 {
  background-color: grey
}

.marker.team.bg-10 {
  background-color: orange
}

.marker.team.bg-11 {
  background-color: olive
}
