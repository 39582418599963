.ui.menu .item.progress {
  flex-grow: 1
}

.ui.menu .ui.progress {
  width: 100%;
  margin: auto 2em;
}

.ui.menu .item.progress .bar {
  min-width: 0.1em;
}
