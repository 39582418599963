.ui.segment.location-paper {
  position: absolute;
  top: 37px;
  right: 1em;
  z-index: 999;
  width: 30em;
  min-height: 88vh;
  max-height: 88vh;
  overflow: scroll;

  svg {
    height: 100%;
    width: 100%;
  }
}
